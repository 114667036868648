import {
  Box,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react"
import { FulfillmentTypes } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useCart } from "~/hooks/useCart"

interface TaxProps {
  taxInclusive: boolean
  taxTotalFloat: number
  taxTotalHuman: string
  fulfillment?: FulfillmentTypes
}

const TaxRow: React.FC<TaxProps> = ({
  taxInclusive,
  taxTotalFloat,
  taxTotalHuman,
  fulfillment,
}) => {
  const { t } = useTranslation()
  const { cart } = useCart()
  const { fulfillment: cartFulfillment } = cart
  const currentFulfillment = fulfillment ?? cartFulfillment

  if (!taxTotalFloat) {
    return <Tr />
  }

  return (
    <Tr>
      <Td paddingX={0} paddingY={2}>
        <HStack spacing={1} alignItems="baseline">
          <Text fontWeight="bold">
            {t("components.checkout.OrderSummaryTotals.TaxRow.Tax")}
          </Text>
          {taxInclusive && (
            <Text fontSize={10}>
              {currentFulfillment === "delivery"
                ? t("components.checkout.OrderSummaryTotals.TaxRow.Inclusive")
                : t(
                    "components.checkout.OrderSummaryTotals.TaxRow.CalculatedInStore"
                  )}
            </Text>
          )}
        </HStack>
      </Td>
      <Td paddingX={0} paddingY={2}>
        <Text textAlign="right">{taxTotalHuman}</Text>
      </Td>
    </Tr>
  )
}

export const SummaryTotals = () => {}

interface OrderSummaryProps {
  freightFloat: number
  freightHuman: string
  taxInclusive: boolean
  taxTotalFloat: number
  taxTotalHuman: string
  totalHuman: string
  discountFloat: number
  discountHuman: string
  couponCode?: string
  fulfillment?: FulfillmentTypes
  isUpdating?: boolean
  isPendingAddress?: boolean
}

const OrderSummaryTotals: React.FC<OrderSummaryProps> = ({
  freightHuman,
  taxInclusive,
  taxTotalFloat,
  taxTotalHuman,
  totalHuman,
  couponCode,
  discountHuman,
  fulfillment,
  isUpdating = false,
  isPendingAddress = false,
}) => {
  const { t } = useTranslation()
  const { defaultLocale } = useApplicationState()

  //Display Tax Inclusive next to Order Total, Canadian sites only
  const displayTaxInclusive = taxInclusive && defaultLocale === "CA"

  const couponCodeRow = (
    <Tr>
      <Td paddingX={0} paddingY={2}>
        <HStack spacing={1} alignItems="baseline">
          <Text fontWeight="bold" color={"inherit"}>
            {t("components.checkout.OrderSummaryTotals.Discount")}
          </Text>
          <Text fontSize={10}>{couponCode}</Text>
        </HStack>
      </Td>
      <Td paddingX={0} paddingY={2}>
        <Text textAlign="right" color="red.500">
          -{discountHuman}
        </Text>
      </Td>
    </Tr>
  )

  return (
    <Box mt={4}>
      <Table size="md" colorScheme="whiteAlpha" mt={-2}>
        <Tbody>
          <Tr>
            <Td paddingX={0} paddingY={2}>
              <HStack spacing={1} alignItems="baseline">
                <Text fontWeight="bold" color={"inherit"}>
                  {t("components.checkout.OrderSummaryTotals.Shipping")}
                </Text>
                {isPendingAddress && (
                  <Text fontSize={10} color={"inherit"}>
                    {t(
                      "components.checkout.OrderSummaryTotals.ShippingPending"
                    )}
                  </Text>
                )}
              </HStack>
            </Td>
            <Td paddingX={0} paddingY={2}>
              <Text textAlign="right">{freightHuman}</Text>
            </Td>
          </Tr>

          <TaxRow
            taxInclusive={taxInclusive}
            taxTotalFloat={taxTotalFloat}
            taxTotalHuman={taxTotalHuman}
            fulfillment={fulfillment}
          />
          {couponCodeRow}
          <Tr borderBottomWidth={1} borderBottomColor="gray.200">
            <Td paddingX={0} paddingY={2}>
              <HStack spacing={1} align="baseline">
                <Text fontWeight="bold" color={"inherit"}>
                  {t("components.checkout.OrderSummaryTotals.Total")}
                </Text>
                {displayTaxInclusive && (
                  <Text fontSize={10} color={"inherit"}>
                    {t("components.checkout.OrderSummaryTotals.TaxInclusive")}
                  </Text>
                )}
              </HStack>
            </Td>
            <Td paddingX={0} paddingY={2} textAlign="right">
              {isUpdating && <Spinner size="sm" color="primary.400" />}
              {!isUpdating && <Text fontWeight="bold">{totalHuman}</Text>}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}

export default OrderSummaryTotals
